@font-face {
  font-family: "CoreSansC";
  src: url("/fonts/CoreSansC.otf");
}

/* LaLiga text 2023 */
@font-face {
  font-family: "LaLigaText";
  src: url("/fonts/laliga-text/laligatext-light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "LaLigaText";
  src: url("/fonts/laliga-text/laligatext-lightitalic.otf");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "LaLigaText";
  src: url("/fonts/laliga-text/laligatext-regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "LaLigaText";
  src: url("/fonts/laliga-text/laligatext-regularitalic.otf");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "LaLigaText";
  src: url("/fonts/laliga-text/laligatext-bold.otf");
  font-weight: 800;
}
@font-face {
  font-family: "LaLigaText";
  src: url("/fonts/laliga-text/laligatext-bolditalic.otf");
  font-style: italic;
  font-weight: 800;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "LaLigaText", "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
  padding-right: 0px !important;
}

#root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

#root > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.grecaptcha-badge {
  display: none;
}
